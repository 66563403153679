/**
 * Calculates the line height based on the provided CSS styles.
 * If the line height is not a number, it uses the font size to calculate the line height.
 * Browsers render line height as 1.2 times the font size by default.
 * @param styles - The CSS styles to calculate the line height from.
 * @returns The calculated line height.
 */
export function calculateLineHeight(styles: CSSStyleDeclaration): number {
  const lineHeight = Number.parseFloat(styles.lineHeight.split('px')[0]);
  if (isNaN(lineHeight)) {
    let fontSize = Number.parseFloat(styles.fontSize.split('px')[0]);
    if (isNaN(fontSize)) {
      fontSize = 13.3333; // default font size
    }
    return fontSize * 1.2;
  }
  return lineHeight;
}

export function isRadioButtonGroupFilled(name: string) {
  const radioButtons: NodeListOf<HTMLInputElement> = document.querySelectorAll(
    `input[type="radio"][name="${name}"]`
  );

  return Array.from(radioButtons).some((radioButton) => radioButton.checked);
}

// convert rgb(255, 255, 255) to [1, 1, 1]
export function calcRgbPercentages(rgb: string): [number, number, number] {
  const rgbValues = rgb
    .slice(4, -1)
    .split(',')
    .map((value) => Number(value));

  return rgbValues.map((value) => value / 255) as [number, number, number];
}

export async function downloadFile(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();

  const element = document.createElement('a');
  element.href = URL.createObjectURL(blob);
  element.download = url.split('/').at(-1) ?? '';

  // Append the link to the body (required for Firefox)
  document.body.appendChild(element);
  // Programmatically click the link to trigger the download
  element.click();
  // Remove the link element after triggering the download
  document.body.removeChild(element);
}
