import { useEffect, useMemo, useState } from 'react';
import { useDocumentState, useDocumentUpdater } from '../../context';
import { usePdf } from '../../hooks/usePdf';
import { PDF_PAGE_WIDTH } from '../../constants';
import { PDFDocument as PDFDoc, PDFFont } from 'pdf-lib';
import PDFDocument from '../PDFDocument';
import CompletionScreen from './CompletionScreen';
import styles from './styles.module.scss';
import {
  drawText,
  drawSignature,
  sortByPosition,
  drawCheckbox,
  drawCombobox
} from './drawUtils';
import fontkit from '@pdf-lib/fontkit';
import DownloadIcon from '../Icons/DownloadIcon';
import { downloadFile } from '../../utils';

const embedFonts = async (pdf: PDFDoc, fonts: Record<string, any>) => {
  const result: Record<string, PDFFont> = {};
  pdf.registerFontkit(fontkit);
  await Promise.all(
    Object.entries(fonts ?? {}).map(async ([font, weights]) => {
      await Promise.all(
        weights.map(async (weight: any) => {
          try {
            const url = weight.source;
            const fontBytes = await fetch(url).then((res) => res.arrayBuffer());
            result[`${font}-${weight.weight}`] = await pdf.embedFont(fontBytes);
          } catch (e) {
            console.error(`Failed to embed font: ${font}-${weight.weight}`);
          }
        })
      );
    })
  );

  return result;
};

const bakeFieldsIntoPdf = (
  pdf: PDFDoc,
  fields: PDF.PDFField[],
  fonts: Record<string, PDFFont>
) => {
  fields.sort(sortByPosition).forEach((field) => {
    if (field.type === 'signature') {
      drawSignature(field, pdf);
    } else if (field.type === 'text') {
      drawText(field, pdf, fonts);
    } else if (field.type === 'radio' || field.type === 'checkbox') {
      drawCheckbox(field, pdf);
    } else if (field.type === 'combobox') {
      drawCombobox(field, pdf, fonts);
    }
  });
};

type PDFViewerProps = {
  onSave: (doc: Blob) => void;
};

const PDFViewer = ({ onSave: _onSave = () => {} }: PDFViewerProps) => {
  const currentIndex = useDocumentState((s) => s.currentIndex);
  const currentFile = useDocumentState((s) => s.documents[currentIndex].file);
  const currentHasSigned = useDocumentState(
    (s) => s.documents[currentIndex].hasSigned
  );
  const currentFields = useDocumentState(
    (s) => s.documents[currentIndex].fields
  );

  const currentFonts = useDocumentState((s) => s.documents[currentIndex].fonts);
  const verified = useDocumentState((s) => s.verified);

  let setHasSigned = useDocumentUpdater((s) => s.setHasSigned);
  let setFieldValue = useDocumentUpdater((s) => s.setFieldValue);

  let [file, setFile] = useState(currentFile);
  let [hasSigned, setHasSignedState] = useState(currentHasSigned);
  let [fields, setFields] = useState(currentFields);
  let pdfDoc = usePdf(file);

  useEffect(() => {
    setFile(currentFile);
    setHasSignedState(currentHasSigned);
    setFields(currentFields);
  }, [currentIndex, currentFile, currentHasSigned, currentFields]);

  const pdfScale = useMemo(() => {
    if (PDF_PAGE_WIDTH < window.innerWidth) {
      return 1;
    }

    return window.innerWidth / PDF_PAGE_WIDTH;
  }, []);

  const isComplete = useMemo(() => {
    const radioGroups: Record<string, boolean> = {};
    // mapped fields are not required to be filled out
    return fields.every((field) => {
      if (field.type === 'radio' && field.required) {
        const group = field.properties.radio_group || field.key;
        if (radioGroups[group] !== undefined) {
          return radioGroups[group];
        }
        const result =
          window.document.querySelectorAll(`input[name="${group}"]:checked`)
            .length > 0;
        radioGroups[group] = result;
        return result;
      } else {
        return (
          !field.required ||
          (field.value !== undefined &&
            field.value !== '' &&
            field.value !== false) ||
          field.isMapped ||
          field.properties?.read_only
        );
      }
    });
  }, [fields]);

  const onFieldChange = (key: string, value: any) => {
    setFieldValue(key, value);
  };

  const [focusFirstRequiredField, setFocusFirstRequiredField] = useState(false);
  const onSave = async () => {
    if (isComplete) {
      const fonts = await embedFonts(pdfDoc as PDFDoc, currentFonts);
      bakeFieldsIntoPdf(pdfDoc as PDFDoc, fields, fonts);

      // make all fields read-only
      pdfDoc
        ?.getForm()
        .getFields()
        .forEach((field) => field.enableReadOnly());

      pdfDoc?.save().then((bytes) => {
        const docBlob = new Blob([bytes], { type: 'application/pdf' });

        _onSave(docBlob);
        setHasSigned(true);
      });
    } else {
      setFocusFirstRequiredField(true);
    }
  };

  if (!hasSigned) {
    return (
      <>
        {file && (
          <>
            <div className={styles.topBar}>
              <a
                href='https://www.feathery.io/'
                target='_blank'
                rel='noreferrer'
              >
                <img src='/Logo-black.svg' alt='Logo' className={styles.logo} />
              </a>
              {verified && (<div className={styles.rightGroup}>
                <DownloadIcon onClick={() => downloadFile(file)}/>
                <button onClick={() => onSave()}>
                  {isComplete ? 'Finish' : 'Continue'}
                </button>
              </div>)}
            </div>
            <div
              className={styles.pdfView}
              style={{
                transform: `scale(${pdfScale})`,
                transformOrigin: '0 0'
              }}
            >
              {!hasSigned && (
                <PDFDocument
                  pdf={{ file, fields }}
                  onFieldChange={onFieldChange}
                  focusFirstRequiredField={focusFirstRequiredField}
                  onFieldFocused={() => setFocusFirstRequiredField(false)}
                />
              )}
            </div>
          </>
        )}
      </>
    );
  } else {
    return <CompletionScreen />;
  }
};

export default PDFViewer;
