import { useEffect, useLayoutEffect, useState } from 'react';
import { useDocumentState, useDocumentUpdater } from '../../context';
import { postDocument } from '../../api/document';
import Verification from '../Verification';
import PDFViewer from '../PDFViewer';
import Dialog from '../Dialog';
import CompletionScreen from '../PDFViewer/CompletionScreen';
import { Navigate } from 'react-router-dom';

type DocumentViewerProps = {
  envelopeId: string;
};

type EnvelopeModalProps = {
  showModal: boolean;
  setModalVisibility: Function;
};

const EnvelopeModal = ({
  showModal,
  setModalVisibility
}: EnvelopeModalProps) => {
  return (
    <Dialog
      isOpen={showModal}
      title='You have another document to sign'
      onClose={() => {
        setModalVisibility(false);
      }}
    >
      <p>
        You will now be taken to another document to fill and sign. The previous
        filled document has been sent to your email.
      </p>
      <button
        onClick={() => {
          setModalVisibility(false);
        }}
      >
        {' '}
        Continue{' '}
      </button>
    </Dialog>
  );
};

const DocumentViewer = ({ envelopeId }: DocumentViewerProps) => {
  const fetchDocument = useDocumentUpdater((s) => s.fetchDocument);
  const updateDocumentIndex = useDocumentUpdater((s) => s.updateDocumentIndex);
  const type = useDocumentState((s) => s.documents[s.currentIndex].type);
  const currentIndex = useDocumentState((s) => s.currentIndex);
  const currentHasSigned = useDocumentState(
    (s) => s.documents[s.currentIndex].hasSigned
  );
  const currentIsExpired = useDocumentState(
    (s) => s.documents[s.currentIndex].isExpired
  );
  const loaded = useDocumentState((s) => s.loaded);
  const documents = useDocumentState((s) => s.documents);
  const [showModal, setModalVisibility] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (loaded) {
      document.fonts.ready.then(() => {
        setReady(true);
      });
    }
  }, [loaded]);

  useLayoutEffect(() => {
    fetchDocument(envelopeId);
  }, [envelopeId, fetchDocument]);

  const handleSave = async (blob: Blob) => {
    let currentDocument = documents[currentIndex];
    await postDocument(currentDocument, blob);

    // check if remaining document to sign
    for (let i = currentIndex + 1; i < documents.length; i++) {
      // skip already signed documents
      if (!documents[i].hasSigned) {
        setModalVisibility(true);
        updateDocumentIndex(i);
        return;
      }
    }

    // No more documents to sign, see if redirect is necessary
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectUrl = urlParams.get('redirect');
    if (redirectUrl) window.location.href = redirectUrl;
  };

  if (currentIsExpired) {
    return <Navigate to='/expired' replace />;
  }

  let DocumentComponent = null;

  switch (type) {
    case 'pdf':
      DocumentComponent = <PDFViewer onSave={handleSave} />;
      break;

    default:
      return null;
  }

  return (
    <>
      <EnvelopeModal
        showModal={showModal}
        setModalVisibility={setModalVisibility}
      />
      {!ready ? (
        <></>
      ) : currentHasSigned ? (
        <CompletionScreen />
      ) : (
        <>
          <Verification envelopeId={envelopeId}>
            {DocumentComponent}
          </Verification>
        </>
      )}
    </>
  );
};

export default DocumentViewer;
